import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.green,
    padding: '25px 25px 5% 5%',
    borderRadius: 20,
    width: '50%',
  },
  formBtnContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: '10px',
    marginBottom: 25,
  },
  cancelBtn: {
    color: theme.palette.green,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: 15,
    width: 30,
    height: 30,
    minWidth: 'unset',
    borderColor: theme.palette.green,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiButton-startIcon': {
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
  cancelIcon: {
    fontSize: '40px !important',
  },
  templatePaper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.green,
    padding: '15px 25px 4% 4%',
    borderRadius: 20,
    width: '30%',
  },
  templateDownloadPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.green,
    padding: '25px 25px 5% 25px',
    borderRadius: 20,
    width: '50%',
  },
}))

function DetailModalComponent(props) {
  const { handleClose, isOpen, children, isTemplateData, isDownload } = props
  const classes = useStyles()

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          {isTemplateData == true && isDownload == false ? (
            <div className={classes.templatePaper}>
              <div className={classes.formBtnContainer}>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon className={classes.cancelIcon} />}
                  className={classes.cancelBtn}
                  onClick={handleClose}
                />
              </div>
              {children}
            </div>
          ) : isTemplateData == false && isDownload == true ? (
            <div className={classes.templateDownloadPaper}>
              <div className={classes.formBtnContainer}>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon className={classes.cancelIcon} />}
                  className={classes.cancelBtn}
                  onClick={handleClose}
                />
              </div>
              {children}
            </div>
          ) : (
            <div className={classes.paper}>
              <div className={classes.formBtnContainer}>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon className={classes.cancelIcon} />}
                  className={classes.cancelBtn}
                  onClick={handleClose}
                />
              </div>
              {children}
            </div>
          )}
        </Fade>
      </Modal>
    </>
  )
}

DetailModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isTemplateData: PropTypes.bool,
  isDownload: PropTypes.bool,
}

export default DetailModalComponent
