import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
// import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

Head.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#41CCC0',
    color: '#ffffff',
  },
}))(TableCell)

function Head(props) {
  const { order, orderBy, headCells } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell.hide !== true && (
              <StyledTableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </StyledTableCell>
            )
        )}
      </TableRow>
    </TableHead>
  )
}

export default Head
